body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Keyframes for the background slideshow */
  @keyframes slideshow {
    0%, 20% { opacity: 1; }
    25%, 45% { opacity: 0; }
    50%, 70% { opacity: 1; }
    75%, 95% { opacity: 0; }
    100% { opacity: 1; }
  }
  
/* .background-image:nth-of-type(1) { animation-delay: 0s; }
.background-image:nth-of-type(2) { animation-delay: 5s; }
.background-image:nth-of-type(3) { animation-delay: 10s; }
.background-image:nth-of-type(4) { animation-delay: 15s; }
.background-image:nth-of-type(5) { animation-delay: 20s; }
.background-image:nth-of-type(6) { animation-delay: 25s; } */

  .slideshow-background {
    position: relative;
    width: 100%;
    height: 100vh; /* Set to the full height of the viewport */
    overflow: hidden; /* Hide overflow to prevent scrollbars */
    background-attachment: fixed;
  }
  
  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; /* Ensure full coverage without stretching */
    animation-name: slideshow;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 20s; /* Adjust the duration to control speed */
    animation-fill-mode: forwards;
    position: fixed; 
  }
  
  .logo {
    position: absolute;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    z-index: 10; /* Ensure it's above the slideshow */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .TextBackdrop {
      width: 90%; /* Give more margin on smaller screens */
    }
  
    .SignInButton {
      padding: 8px 30px; /* Smaller padding on smaller screens */
      font-size: 0.9rem; /* Smaller font size on smaller screens */
    }
  
    .logo {
      width: 80px; /* Smaller logo on smaller screens */
      height: 80px;
    }
    /* .background-image {
      height: auto;
    } */
    .slideshow-background {
        position: relative;
        width: 100%;
        height: 100vh; /* Set to the full height of the viewport */
        overflow: hidden; /* Hide overflow to prevent scrollbars */
      }
  }
  
  @media (max-width: 480px) {
    .StyledTypography, .IntroText {
      font-size: 0.8rem; /* Even smaller font size on very small screens */
    }
  }
  
  /* Adjust logo size for larger screens */
@media (min-width: 768px) {
    .logo {
      width: 80px; /* Adjust the logo size as needed */
    }
    
    /* Adjust TextBackdrop for larger screens to make sure it's visible */
    .TextBackdrop {
      width: auto; /* Auto width to fit content */
      padding-left: 200px; /* Push content from the left */
    }
  }